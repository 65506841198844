import 'bootstrap/js/dropdown';



document.addEventListener('DOMContentLoaded', function () {
	
	// La décoration des champs requis
	if ($(':required').length > 0) {
		$(':required').wrap('<div class="input-group">').after('<span class="input-group-addon input-sm">*</span>');
		$('form').append('<p class="text-center clearfix"><em>Les champs marqués du signe * sont obligatoires</em></p>');
	}
	// Faire confirmer les boutons graves
	document.querySelectorAll('.confirm').forEach(elm => {
		if (elm.classList.contains('confirmed')) {
			return false;
		}
		elm.classList.add('confirmed');
		elm.addEventListener('click', ev => {
			if (confirm('Êtes-vous sur de vouloir ' + elm.textContent.trim().toLocaleLowerCase() + ' cet élément ?')) {
				return true;
			} else {
				ev.preventDefault();
				return false;
			}
		}, true);
	});



});
